const {
  ReactPaper: Paper,
  ReactButton: Button,
  ReactIcon: Icon
} = svs.ui;
export const ErrorPaper = ({
  isLoading,
  onRetryLoadData
}) => <Paper className="error-paper padding-xs-2 padding-am-4 f-500 u-text-center">
    <Icon color="fc-red" icon="icecream" size="900" />
    <h3 className="margin-top-2">Informationen kunde inte hämtas</h3>
    <p>
      Vänligen försök igen eller kontakta ombudssupport&nbsp;
      <span style={{
      whiteSpace: 'nowrap'
    }}>020-78 83 55</span>
      .
    </p>
    {onRetryLoadData && <Button disabled={isLoading} onClick={() => onRetryLoadData()}>
        {isLoading ? 'Hämtar...' : 'Försök igen'}
      </Button>}
  </Paper>;
ErrorPaper.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onRetryLoadData: PropTypes.func.isRequired
};
svs.economy = svs.economy || {};
svs.economy.ui = svs.economy.ui || {};
svs.economy.ui.papers = svs.economy.ui.papers || {};
svs.economy.ui.papers.errorPaper = svs.economy.ui.papers.errorPaper || {};
svs.economy.ui.papers.errorPaper.ErrorPaper = ErrorPaper;