import { loginStart, loginClearValidationErrors, changeRetailerId } from '../store/login/login.actions';
import { UsernameLogin } from '../components/username-login';
const {
  useCallback
} = React;
const {
  useDispatch,
  useSelector
} = ReactRedux;
export const UsernameLoginContainer = () => {
  const dispatch = useDispatch();
  const {
    isLoading,
    isComplete,
    retailerId,
    rememberRetailerId: storeRememberRetailerId,
    validationErrors
  } = useSelector(({
    login
  }) => login);
  const hasToChangePassword = useSelector(({
    login
  }) => login.isComplete && login.mustChangePassword);
  const hasValidationErrors = Boolean(validationErrors);
  const handleClearValidationError = useCallback(() => {
    if (hasValidationErrors) {
      dispatch(loginClearValidationErrors());
    }
  }, [dispatch, hasValidationErrors]);
  const handleChangeRetailerId = retailerId => {
    dispatch(changeRetailerId(retailerId));
  };
  const onSubmit = args => {
    dispatch(loginStart(args));
  };
  return <UsernameLogin hasToChangePassword={hasToChangePassword} isComplete={isComplete} isLoading={isLoading} isRememberRetailerIdChecked={storeRememberRetailerId} onChangeRetailerId={handleChangeRetailerId} onClearValidationError={handleClearValidationError} onSubmit={onSubmit} retailerId={retailerId} />;
};