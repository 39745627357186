import { sanitizeRetailerId } from '@common/utils';
const {
  createApi,
  fetchBaseQuery
} = RTKQ;
const {
  fetch,
  url: baseUrl
} = svs.core.jupiterFetch;
export const AUTHENTICATE_PROGRESS_STATUS = {
  OUTSTANDING_TRANSACTION: 1,
  NO_CLIENT: 2,
  STARTED: 3,
  USER_SIGN: 4,
  COMPLETE: 6
};
export const bankIdApi = createApi({
  reducerPath: 'bankIdApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl()}/retailer/1/authenticate`,
    credentials: 'include',
    fetchFn: fetch.fetch
  }),
  refetchOnMountOrArgChange: 10,
  endpoints: builder => ({
    initiateLogin: builder.mutation({
      transformResponse: async ({
        autoStartToken,
        qrData
      }) => ({
        autoStartToken,
        qrData
      }),
      query: retailerId => ({
        body: {
          retailerId: sanitizeRetailerId(retailerId)
        },
        method: 'post',
        url: ''
      })
    }),
    getAuthenticateStatus: builder.query({
      query: autoStartToken => ({
        body: {
          autoStartToken
        },
        method: 'post',
        url: '/login'
      })
    })
  })
});