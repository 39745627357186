import { LaunchBankIdView } from './launch-bank-id-view';
import { LoginForm } from './login-form';
const {
  Grid,
  GridCol,
  GridRow
} = svs.ui.ReactGrid;
export const BankIdLogin = ({
  autoStartToken,
  errorMessage,
  isLoading,
  isLoginThisDevice,
  isLoginFinished,
  isRememberRetailerIdChecked,
  onBankIdAuthenticateStart,
  onCancelLogin,
  onChangeRetailerId,
  onCloseErrorDialog,
  qrData,
  retailerId
}) => <Grid>
    <GridRow>
      <GridCol am={{
      col: 8,
      offset: 2
    }}>
        {!autoStartToken && <LoginForm errorMessage={errorMessage} isLoading={isLoading} isLoginThisDevice={isLoginThisDevice} isRememberRetailerIdChecked={isRememberRetailerIdChecked} onBankIdAuthenticateStart={onBankIdAuthenticateStart} onChangeRetailerId={onChangeRetailerId} onCloseErrorDialog={onCloseErrorDialog} retailerId={retailerId} />}

        {Boolean(autoStartToken) && <LaunchBankIdView autoStartToken={autoStartToken} isLoginFinished={isLoginFinished} isLoginThisDevice={isLoginThisDevice} onCancelLogin={onCancelLogin} qrData={qrData} />}
      </GridCol>
    </GridRow>
  </Grid>;