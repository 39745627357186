import { ErrorPaper } from '../error-paper';
import { PageHeader } from '../page-header';
const {
  useEffect,
  useState
} = React;
const {
  Route,
  useHistory
} = ReactRouterDOM;
const {
  ErrorBoundary
} = svs.components.errorBoundary;
const ErrorPage = ({
  retry
}) => {
  const {
    location
  } = useHistory();
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [prevLocation, setPrevLocation] = useState(location);
  useEffect(() => {
    if (isInitialLoad) {
      setIsInitialLoad(false);
      return;
    }
    if (location !== prevLocation) {
      setPrevLocation(location);
      retry();
    }
  }, [isInitialLoad, location, prevLocation, retry]);
  return <div className="ompo-main-content padding-v-4">
      <PageHeader hasBreadcrumb={false} title="Någonting gick fel" />
      <ErrorPaper isLoading={false} onRetryLoadData={() => window.location.reload()} />
    </div>;
};
export const ErrorBoundedRoute = ({
  children,
  component: Component,
  render,
  ...props
}) => <ErrorBoundary fallback={ErrorPage} reloadOnRetry={false}>
    {}
    {Component && <Route {...props} render={routeProps => <Component {...routeProps} />} />}
    {}
    {render && <Route {...props} render={render} />}
    {children && <Route {...props}>
        {children}
      </Route>}
  </ErrorBoundary>;