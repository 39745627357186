import { AUTHENTICATE_COMPLETE, CHANGE_RETAILER_ID, LOGIN_CLEAR_ERROR, LOGIN_START, LOGIN_COMPLETE, LOGIN_ERROR, LOGIN_CLEAR_VALIDATION_ERRORS, LOGIN_SET_REMEMBER_RETAILER_ID, STORE_USER_METADATA_START, STORE_USER_METADATA_ERROR, STORE_USER_METADATA_COMPLETE } from './login.action-types';
const logger = svs.core.log.getLogger('welcome/store/login/actions');
export const changeRetailerId = retailerId => ({
  type: CHANGE_RETAILER_ID,
  retailerId
});
export const authenticateComplete = ({
  isBankIdLogin,
  isLoginThisDevice,
  mustChangePassword
} = {}) => {
  const action = {
    type: AUTHENTICATE_COMPLETE,
    isBankIdLogin,
    isLoginThisDevice,
    mustChangePassword
  };
  logger.info('Authenticate complete', {
    action
  });
  return action;
};
export const loginClearError = () => ({
  type: LOGIN_CLEAR_ERROR
});
export const storeUserMetadata = userProfile => (dispatch, getState) => {
  dispatch({
    type: STORE_USER_METADATA_START
  });
  if (!userProfile || !userProfile.retailer) {
    logger.error(`loginComplete: completing login with reduced capabilities.`, {
      state: getState()
    });
    return dispatch({
      type: STORE_USER_METADATA_ERROR
    });
  }
  const retailerInfo = {
    basicCategory: userProfile.retailer.basicCategory,
    retailerId: userProfile.retailer.id,
    sellType: userProfile.retailer.sellType,
    sellTypeId: userProfile.retailer.sellTypeId
  };
  const missingFields = Object.keys(retailerInfo).filter(k => !retailerInfo[k]).join(', ');
  const userInfo = {
    name: userProfile.user.name,
    retailer: retailerInfo,
    role: userProfile.user?.role
  };
  svs.core.userSession.set('userInfo', userInfo, err => {
    if (err || missingFields.length > 0) {
      if (err) {
        logger.error(`Failed to store user session, will continue with reduced capabilities and will notify the user.`, err);
      } else {
        logger.error(`loginComplete: missing fields in retailerInfo: ${missingFields}. Completing with reduced data and capabilities.`, {
          state: getState()
        });
      }
      return dispatch({
        type: STORE_USER_METADATA_ERROR
      });
    }
    logger.info('Successfully stored user session containing retailerInfo', retailerInfo);
    dispatch({
      type: STORE_USER_METADATA_COMPLETE
    });
  });
};
export const loginStart = ({
  retailerId,
  username,
  password,
  rememberRetailerId
} = {}) => ({
  type: LOGIN_START,
  retailerId,
  username,
  password,
  rememberRetailerId
});
export const loginComplete = () => {
  logger.info(`Login complete for user.`);
  return {
    type: LOGIN_COMPLETE
  };
};
export const loginError = error => ({
  type: LOGIN_ERROR,
  error
});
export const loginClearValidationErrors = () => ({
  type: LOGIN_CLEAR_VALIDATION_ERRORS
});
export const setRememberRetailerId = rememberRetailerId => ({
  type: LOGIN_SET_REMEMBER_RETAILER_ID,
  rememberRetailerId
});