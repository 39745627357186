import { LOGIN_COMPLETE } from '../login/login.action-types';
const {
  deviceSession
} = svs.core;
const rememberRetailerId = store => next => action => {
  if (action.type === LOGIN_COMPLETE) {
    const {
      login
    } = store.getState();
    if (login.rememberRetailerId) {
      deviceSession.set('loginRememberRetailerId', null, login.retailerId, () => {
        next(action);
      });
    } else {
      deviceSession.remove('loginRememberRetailerId', null, () => {
        next(action);
      });
    }
  }
  return next(action);
};
export { rememberRetailerId };