import { LabelWrapper } from '../label-wrapper';
import { InfoOmbudsnummer } from '../info-ombudsnummer';
import './styles.less';
const {
  useCallback,
  useEffect,
  useState
} = React;
const {
  useHistory
} = ReactRouterDOM;
const {
  Loader
} = svs.components.ReactLoader;
const {
  ReactButton: Button
} = svs.ui;
const {
  TextInput
} = svs.ui.ReactFormInputs;
const {
  Checkbox,
  Form
} = svs.ui.ReactForm;
const {
  queryString
} = svs.utils.url;
const {
  Grid,
  GridCol,
  GridRow
} = svs.ui.ReactGrid;
export const UsernameLogin = ({
  hasToChangePassword,
  isComplete,
  isLoading,
  isRememberRetailerIdChecked,
  onChangeRetailerId,
  onClearValidationError,
  onSubmit,
  retailerId,
  validationErrors
}) => {
  const history = useHistory();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [rememberRetailerId, setRememberRetailerId] = useState(isRememberRetailerIdChecked);
  const handleRetailerIdChange = useCallback(e => {
    onChangeRetailerId(e.target.value);
    onClearValidationError();
  }, [onChangeRetailerId, onClearValidationError]);
  const handleUsernameChange = useCallback(e => {
    setUsername(e.target.value);
    onClearValidationError();
  }, [onClearValidationError]);
  const handlePasswordChange = useCallback(e => {
    setPassword(e.target.value);
    onClearValidationError();
  }, [onClearValidationError]);
  const handleRememberRetailerIdChange = useCallback(() => {
    setRememberRetailerId(!rememberRetailerId);
  }, [rememberRetailerId]);
  const validatePassword = useCallback(() => {
    if (validationErrors && validationErrors.password) {
      return {
        errorMessage: validationErrors.password,
        isValid: false
      };
    }
    return {
      isValid: true
    };
  }, [validationErrors]);
  const handleSubmit = useCallback((e, {
    retailerId,
    username,
    password
  }, isValid) => {
    e.preventDefault();
    if (isValid) {
      onSubmit({
        retailerId: retailerId.value,
        username: username.value,
        password: password.value,
        rememberRetailerId
      });
    }
  }, [onSubmit, rememberRetailerId]);
  useEffect(() => {
    if (isComplete && hasToChangePassword) {
      const {
        returnUrl
      } = queryString;
      history.push(`/logga-in/byt-losenord${returnUrl ? `?returnUrl=${returnUrl}` : ''}`);
    }
  }, [history, isComplete, hasToChangePassword]);
  return <Grid>
      <GridRow>
        <GridCol am={{
        col: 8,
        offset: 2
      }}>
          <Form className="control-group margin-bottom-0" onSubmit={handleSubmit} setError={validationErrors}>
            <h1 className="f-900 f-medium margin-bottom-1 u-text-center">
              Logga in med användarnamn
            </h1>
            <TextInput disabled={isLoading} errorMessageRequired="Du måste ange ett ombudsnummer" inverted isRequired label="Ombudsnummer" name="retailerId" onChange={handleRetailerIdChange} value={retailerId} />
            <LabelWrapper className="margin-bottom-1">
              Endast siffror
            </LabelWrapper>
            <InfoOmbudsnummer />
            <Checkbox checked={rememberRetailerId} inverted name="rememberRetailerId" onChange={handleRememberRetailerIdChange}>
              Kom ihåg ombudsnummer
            </Checkbox>
            <TextInput disabled={isLoading} errorMessageRequired="Du måste anget ett användarnamn" inverted isRequired label="Användarnamn" min={2} name="username" onChange={handleUsernameChange} value={username} />

            <TextInput disabled={isLoading} errorMessageRequired="Du måste anget ett lösenord" inverted isRequired label="Lösenord" name="password" onChange={handlePasswordChange} type="password" validateFn={validatePassword} value={password} />

            <div className="control-group form-v2">
              <Button block className="qa-login" type="submit">
                {isLoading ? <Loader size={200} /> : 'LOGGA IN'}
              </Button>
            </div>
          </Form>
        </GridCol>
      </GridRow>
    </Grid>;
};