import { NotFoundComponent, Router } from '@common/components/router';
import { ErrorBoundedRoute } from '@common/components/router/error-bounded-route';
import Welcome from './components/welcome';
import { authenticateConnector } from './store/middleware/authenticate-connector';
import { rememberRetailerId } from './store/middleware/remember-retailerid';
import { default as login } from './store/login/login.reducer.js';
import ForcePasswordChange from './components/force-password-change';
import { LoginContainer } from './containers/login-container';
import { bankIdApi } from './store/bank-id-api';
import { retailerApi } from '@common/retailer-api';
import { createErrorLogger } from '@common/middlewares/error-logger';
import { maskSocialSecurityNumber } from '@common/utils';
setGlobal('svs.welcome.utils.maskSocialSecurityNumber', maskSocialSecurityNumber);
const {
  Switch
} = ReactRouterDOM;
const {
  Provider
} = ReactRedux;
const {
  configureStore
} = RTK;
const {
  store: passwordChangeStore
} = svs.retail.PasswordChange;
const {
  ErrorBoundary
} = svs.components.errorBoundary;
const {
  SiteWrapper
} = svs.retailUi;
const reducers = {
  [bankIdApi.reducerPath]: bankIdApi.reducer,
  [retailerApi.reducerPath]: retailerApi.reducer,
  login,
  passwordChange: passwordChangeStore.reducer
};
const data = svs.welcome.data || {};
const store = configureStore({
  reducer: reducers,
  preloadedState: data.initialState,
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(authenticateConnector, bankIdApi.middleware, retailerApi.middleware, rememberRetailerId, passwordChangeStore.middleware, createErrorLogger('welcome'))
});
const targetNode = document.getElementById('react-root');
if (targetNode) {
  ReactDOM.render(<ErrorBoundary>
      <Provider store={store}>
        <Router>
          <SiteWrapper hasHeader={false} hasMenu={false}>
            <Switch>
              <ErrorBoundedRoute component={ForcePasswordChange} path="/logga-in/byt-losenord" />
              <ErrorBoundedRoute component={LoginContainer} path="/logga-in" />
              <ErrorBoundedRoute component={Welcome} path="/" />
              <ErrorBoundedRoute component={NotFoundComponent} />
            </Switch>
          </SiteWrapper>
        </Router>
      </Provider>
    </ErrorBoundary>, targetNode);
}