import { maskSocialSecurityNumber } from '@common/utils';
export const createErrorLogger = moduleName => {
  const logger = svs.core?.log?.getLogger(`common/error-logger-middleware/${moduleName}`);
  return () => next => action => {
    if (RTK?.isRejectedWithValue(action)) {
      const resPayload = action.payload || {};
      const {
        supportUrl,
        ...resError
      } = resPayload.data?.error || {};
      const logFn = resPayload.status >= 500 ? logger.error : logger.info;
      const reqUrl = new URL(action.meta.baseQueryMeta?.request?.url);
      const maskedArgs = maskSocialSecurityNumber(action.meta.arg.originalArgs);
      logFn(`${action.meta.arg.endpointName} returned ${resPayload.status} with error code ${resError.code}`, maskedArgs, resError, reqUrl.pathname);
    } else if (!action.type.endsWith('/executeQuery/rejected') && action.error) {
      logger.info(action, action.error.stack);
    }
    return next(action);
  };
};