import papers from '../images/papers.png';
import { isRetailerAllowedToViewMaterialshop } from './utils';
const {
  retailerId
} = svs.core.session.data.userSession;
const orderMaterialFeauture = svs.core.detect.feature('ft-order-material');
const orderMaterial = isRetailerAllowedToViewMaterialshop(retailerId) ? {
  '/bestall-material': {
    label: 'Beställ material',
    header: {
      backgroundImage: papers
    }
  }
} : {};
const myOrders = isRetailerAllowedToViewMaterialshop(retailerId) ? {
  '/mina-bestallningar': {
    label: 'Mina beställningar',
    header: {
      backgroundImage: papers
    }
  }
} : {};
const campaigns = orderMaterialFeauture ? {
  '/kampanjutskick': {
    label: 'Kampanjutskick',
    header: {
      backgroundImage: papers
    }
  }
} : {};
const metadata = {
  title: 'Material',
  routes: {
    '/oversikt': {
      label: 'Översikt',
      header: {
        backgroundImage: papers
      }
    },
    ...orderMaterial,
    ...myOrders,
    ...campaigns,
    '/tilldelning': {
      label: 'Tilldelning',
      header: {
        backgroundImage: papers
      }
    },
    '/skyltmaterial': {
      label: 'Skyltmaterial',
      header: {
        backgroundImage: papers
      }
    }
  }
};
export { metadata };