import { SELL_TYPE_IDS } from '@common/constants';
const {
  createSelector
} = RTK;
const {
  log: {
    getLogger
  }
} = svs.core;
const logger = getLogger('common:selectors');
export const selectIsSpelombud = createSelector(state => state.User, User => {
  let sellTypeId = User?.retailer?.sellTypeId;
  if (!sellTypeId) {
    logger.error('[selectIsSpelombud] Failed to read retailerSellTypeId from User slice, setting value 1 as fallback.', {
      User
    });
    sellTypeId = SELL_TYPE_IDS.SPEL_LOTT;
  } else {
    logger.info('[selectIsSpelombud] Got retailerSellTypeId from User slice.', {
      User
    });
  }
  return [SELL_TYPE_IDS.SPEL, SELL_TYPE_IDS.SPEL_LOTT].includes(sellTypeId);
});