import { getClassNamesFromArray } from '@common/utils';
import { LabelWrapper } from '../label-wrapper';
import { InfoOmbudsnummer } from '../info-ombudsnummer';
import './login-form.less';
const {
  useState
} = React;
const {
  ReactBank: Bank,
  ReactButton: Button
} = svs.ui;
const {
  Loader
} = svs.components.ReactLoader;
const {
  Checkbox,
  Inputv2
} = svs.ui.ReactForm;
const {
  Dialog
} = svs.retailUi;
const {
  formfactor
} = svs.core.detect;
export const LoginForm = ({
  errorMessage,
  isLoading,
  isLoginThisDevice,
  isRememberRetailerIdChecked,
  onBankIdAuthenticateStart,
  onChangeRetailerId,
  onCloseErrorDialog,
  retailerId
}) => {
  const handleChangeRetailerId = ({
    target: {
      value
    }
  }) => {
    onChangeRetailerId(value);
  };
  const [rememberRetailerId, setRememberRetailerId] = useState(isRememberRetailerIdChecked);
  const isMobile = formfactor.mobile();
  return <React.Fragment>
      {errorMessage && <Dialog buttonCallback={onCloseErrorDialog} buttonTitle="Okej" icon="rectify-error" message={errorMessage} severe title="Det gick inte att logga in" />}
      <h1 className="f-900 f-medium margin-bottom-1 u-text-center">
        Logga in med bank-id
      </h1>

      <div className="control-group form-v2">
        <Inputv2 disabled={isLoading} inverted label="Ombudsnummer" name="retailerId" onChange={handleChangeRetailerId} value={retailerId} />
        <LabelWrapper className="margin-bottom-1">
          Endast siffror
        </LabelWrapper>
        <InfoOmbudsnummer />
        <Checkbox checked={rememberRetailerId} inverted name="rememberRetailerId" onChange={() => setRememberRetailerId(!rememberRetailerId)}>
          Kom ihåg ombudsnummer
        </Checkbox>
      </div>

      <div className={getClassNamesFromArray(['u-flex u-flex-column control-group form-v2 u-flex-gap-2', !isMobile && 'u-flex-column-reverse'])}>
        <Button block disabled={!retailerId} iconPosition="right" onClick={() => onBankIdAuthenticateStart({
        isThisDevice: true,
        rememberRetailerId
      })} transparent={!isMobile}>
          {isLoginThisDevice === false && isLoading && <Loader size={200} />}
          {(isLoginThisDevice === false || !isLoading) && <React.Fragment>
              <Bank name={isMobile ? 'bankid' : 'bankid-neg'} size={36} />
              Bank-id på denna enhet
            </React.Fragment>}
        </Button>
        <Button block disabled={!retailerId} iconPosition="right" onClick={() => onBankIdAuthenticateStart({
        isThisDevice: false,
        rememberRetailerId
      })} transparent={isMobile}>
          {isLoginThisDevice === true && isLoading && <Loader size={200} />}
          {(isLoginThisDevice === true || !isLoading) && <React.Fragment>
              <Bank name={isMobile ? 'bankid-neg' : 'bankid'} size={36} />
              Bank-id på annan enhet
            </React.Fragment>}
        </Button>
      </div>
    </React.Fragment>;
};