export const metadata = {
  title: 'Ekonomi',
  routes: {
    '/oversikt': {
      label: 'Översikt'
    },
    '/forsaljning': {
      label: 'Försäljning'
    },
    '/provisioner': {
      label: 'Provisioner'
    },
    '/rapporter': {
      label: 'Rapporter'
    },
    '/transaktioner': {
      label: 'Transaktioner'
    }
  }
};