import './launch-bank-id-view.less';
const {
  ReactButton: Button
} = svs.ui;
const {
  detect
} = svs.core;
const QRCode = svs.components.libs.ReactQRCode;
const getBankIdRedirectUrl = () => {
  if (!detect.os.iOS()) {
    return 'null';
  }
  if (detect.browser.crios()) {
    return 'googlechromes://';
  }
  if (detect.browser.firefox()) {
    return `firefox://open-url?url=${window.location.href}`;
  }
  return `${window.location.href}`;
};
const getBankIdAppLink = autoStartToken => {
  const isAndroidChrome = detect.os.android() && detect.browser.chrome();
  const redirectUrl = encodeURIComponent(getBankIdRedirectUrl());
  if (detect.os.iOS() || isAndroidChrome) {
    return `https://app.bankid.com/?autostarttoken=${autoStartToken}&redirect=${redirectUrl}`;
  }
  return `bankid:///?autostarttoken=${autoStartToken}&redirect=${redirectUrl}`;
};
export const LaunchBankIdView = ({
  autoStartToken,
  isLoginThisDevice,
  qrData,
  onCancelLogin
}) => {
  const launchBankIdApp = () => {
    window.location.assign(getBankIdAppLink(autoStartToken));
  };
  if (isLoginThisDevice) {
    return <React.Fragment>
        <Button block onClick={launchBankIdApp}>
          Öppna Bank-id-appen
        </Button>
        <Button block className="margin-top-2" onClick={onCancelLogin} transparent>
          Avbryt
        </Button>
      </React.Fragment>;
  }
  return <div className="qr-view">
      <div className="qr-wrapper">
        <QRCode className="margin-xs-top" size={120} value={qrData} />
      </div>
      <p>
        Starta din bank-id-app på din mobil eller surfplatta. Klicka på QR-ikonen och läs av QR-koden ovan med kameran.
      </p>
      <Button block onClick={onCancelLogin} transparent>
        Avbryt
      </Button>
    </div>;
};
setGlobal('svs.welcome.getBankIdAppLink', getBankIdAppLink);