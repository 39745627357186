import { isUserAdmin } from '@common/utils';
const metadata = {
  title: 'Min butik',
  routes: {
    '/oversikt': {
      label: 'Översikt'
    },
    '/tavlingar': {
      label: 'Tävlingar'
    },
    '/kampanjer': {
      label: 'Kampanjer'
    },
    '/mitt-ombud': {
      label: 'Mitt ombud'
    },
    '/mitt-team': {
      label: 'Mitt team',
      useIsAllowed: isUserAdmin
    }
  }
};
export { metadata };