import { selectIsSpelombud } from '@common/selectors';
import europamastarenLogo from '../images/europamastaren-logo.png';
import europamastaren from '../images/europamastaren.png';
const tipsSmFeauture = svs.core.detect.feature('ft-tips-sm');
const tipsSmMetadata = tipsSmFeauture ? {
  '/tips-sm': {
    label: 'Tips-SM',
    navBar: {
      style: {
        color: '#fff',
        backgroundColor: '#142E4F'
      },
      activeStyle: {
        color: 'white',
        'border-bottom-color': '#fff'
      }
    },
    useIsAllowed: () => {
      return ReactRedux.useSelector(selectIsSpelombud);
    }
  }
} : {};
const europamastarenFeauture = svs.core.detect.feature('ft-europamastaren');
const europamastarenMetadata = europamastarenFeauture ? {
  '/europamastaren': {
    label: 'Europamästaren',
    header: {
      backgroundImage: europamastaren,
      logo: europamastarenLogo
    },
    navBar: {
      style: {
        color: '#fff',
        backgroundColor: '#004D1D'
      },
      activeStyle: {
        color: 'white',
        'border-bottom-color': '#fff'
      }
    },
    useIsAllowed: () => {
      return ReactRedux.useSelector(selectIsSpelombud);
    }
  }
} : {};
const metadata = {
  title: 'Tävlingar',
  routes: {
    '/saljtavlingar': {
      label: 'Säljtävlingar'
    },
    '/vinnargalan': {
      label: 'Vinnargalan',
      navBar: {
        style: {
          backgroundColor: '#fae4be'
        }
      }
    },
    ...tipsSmMetadata,
    ...europamastarenMetadata
  }
};
export { metadata };