import { getClassNamesFromArray } from '@common/utils';
import './styles.less';
export const PageIngress = ({
  children,
  className
}) => <div className={getClassNamesFromArray(['page-ingress padding-bottom-1 f-content', className])}>
    <div className="ompo-main-content">
      {children}
    </div>
  </div>;
PageIngress.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};