export const AUTHENTICATE_COMPLETE = 'LOGIN/AUTHENTICATE_COMPLETE';
export const CHANGE_RETAILER_ID = 'LOGIN/CHANGE_RETAILER_ID';
export const LOGIN_CLEAR_ERROR = 'LOGIN/CLEAR_ERROR';
export const LOGIN_START = 'LOGIN/START';
export const LOGIN_COMPLETE = 'LOGIN/COMPLETE';
export const LOGIN_ERROR = 'LOGIN/ERROR';
export const LOGIN_CLEAR_VALIDATION_ERRORS = 'LOGIN/CLEAR_VALIDATION_ERRORS';
export const LOGIN_SET_REMEMBER_RETAILER_ID = 'LOGIN/LOGIN_SET_REMEMBER_RETAILER_ID';
export const STORE_USER_METADATA_START = 'LOGIN/STORE_USER_METADATA_START';
export const STORE_USER_METADATA_ERROR = 'LOGIN/STORE_USER_METADATA_ERROR';
export const STORE_USER_METADATA_COMPLETE = 'LOGIN/STORE_USER_METADATA_COMPLETE';