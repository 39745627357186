import backgroundLeft from './bg-left.png';
import backgroundRight from './bg-right.png';
import './styles.less';
const {
  cdn
} = svs.core.config.data.svsconfig;
export const WelcomeBackground = ({
  children,
  className,
  isDark
}) => <div className={`welcome-background ${className}`}>
    <div className="welcome-bg bg-left" style={{
    backgroundImage: `url(${cdn + backgroundLeft})`
  }} />
    <div className="welcome-bg bg-right" style={{
    backgroundImage: `url(${cdn + backgroundRight})`
  }} />
    {isDark && <div className="dark-bg" />}

    <div className="welcome-background-inner margin-top-4 margin-center">
      {children}
    </div>
  </div>;
WelcomeBackground.defaultProps = {
  className: ''
};
WelcomeBackground.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  isDark: PropTypes.bool
};