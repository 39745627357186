import { metadata as myStoreMetadata } from '../../../modules/my-store/src/metadata';
import { metadata as economyMetadata } from '../../../modules/economy/src/metadata';
import { metadata as competitionsMetadata } from '../../../modules/competitions/src/metadata';
import { metadata as knowledgeMetadata } from '../../../modules/knowledge/src/metadata';
import { metadata as newsMetadata } from '../../../modules/news/src/metadata';
import { metadata as materialMetaData } from '../../../modules/material/src/metadata';
const {
  urlMapping
} = svs.core;
export const getCurrentBasePath = () => window.location.pathname.split('/').slice(0, 2).join('/');
export const getMetadataForPath = () => {
  const path = getCurrentBasePath();
  let metadata;
  switch (path) {
    case urlMapping.get('economyHome'):
      metadata = economyMetadata;
      break;
    case urlMapping.get('competitionsHome'):
      metadata = competitionsMetadata;
      break;
    case urlMapping.get('knowledgeHome'):
      metadata = knowledgeMetadata;
      break;
    case urlMapping.get('materialHome'):
      metadata = materialMetaData;
      break;
    case urlMapping.get('myBusinessHome'):
      metadata = myStoreMetadata;
      break;
    case urlMapping.get('newsHome'):
      metadata = newsMetadata;
      break;
    case urlMapping.get('spelkollHome'):
      {
        metadata = {
          title: 'Spelkoll',
          routes: {
            '/oversikt': {
              label: 'Översikt',
              hasBreadcrumb: false
            }
          }
        };
        break;
      }
    default:
      return null;
  }
  return {
    ...metadata,
    routes: metadata.routes
  };
};