import { BankIdLoginContainer } from '../../containers/bank-id-login-container';
import { UsernameLoginContainer } from '../../containers/username-login-container';
import { WelcomeBackground } from '../welcome-background';
import './styles.less';
const {
  RedirectToReturnUrl
} = svs.retailUtils;
const {
  NavLink,
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch
} = ReactRouterDOM;
const {
  Dialog
} = svs.retailUi;
const {
  ItemsMenu,
  LinkItem
} = svs.ui.ReactItemsMenu;
export const Login = ({
  error,
  hasToChangePassword,
  hasMissingUserMetadata,
  isComplete,
  onCloseErrorDialog,
  onCloseMetadataErrorDialog
}) => {
  const match = useRouteMatch();
  const history = useHistory();
  return <WelcomeBackground className="login-component padding-top-2 padding-bottom-4" isDark>
      {isComplete && !hasToChangePassword && <RedirectToReturnUrl />}
      {!isComplete && hasMissingUserMetadata && <Dialog buttonCallback={onCloseMetadataErrorDialog} buttonTitle="Okej" icon="exclamation-sign" message="Det gick inte att hämta all information om ditt konto. Viss funktionalitet kommer inte vara tillgänglig." title="Någonting gick fel" />}

      {error && <Dialog buttonCallback={onCloseErrorDialog} buttonTitle="Okej" icon={error.icon} message={error.message} severe={error.severe} title={error.title} type={error.type} />}

      <ItemsMenu centered>
        <LinkItem activeClassName="login-menu-active" Component={NavLink} label="Bank-id" to={`${match.path}/bank-id${history.location.search}`} />
        <LinkItem activeClassName="login-menu-active" Component={NavLink} label="Användarnamn" to={`${match.path}/anvandarnamn${history.location.search}`} />
      </ItemsMenu>

      <Switch>
        <Route component={BankIdLoginContainer} path={`${match.path}/bank-id`} />
        <Route component={UsernameLoginContainer} path={`${match.path}/anvandarnamn`} />
        <Redirect to={{
        pathname: `${match.path}/bank-id`,
        search: window.location.search
      }} />
      </Switch>
    </WelcomeBackground>;
};