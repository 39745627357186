import { AUTHENTICATE_COMPLETE, CHANGE_RETAILER_ID, LOGIN_CLEAR_ERROR, LOGIN_ERROR, LOGIN_START, LOGIN_COMPLETE, LOGIN_CLEAR_VALIDATION_ERRORS, LOGIN_SET_REMEMBER_RETAILER_ID, STORE_USER_METADATA_START, STORE_USER_METADATA_ERROR, STORE_USER_METADATA_COMPLETE } from './login.action-types';
const defaultState = {
  hasStoredUserMetadata: false,
  isLoading: false,
  isAuthenticated: false,
  isComplete: false,
  isMissingUserMetadata: false,
  error: null,
  validationError: null,
  mustChangePassword: false,
  rememberRetailerId: false,
  retailerId: null
};
function loginReducer(state = defaultState, action = {}) {
  switch (action.type) {
    case AUTHENTICATE_COMPLETE:
      return {
        ...state,
        isAuthenticated: true,
        mustChangePassword: action.mustChangePassword
      };
    case CHANGE_RETAILER_ID:
      return {
        ...state,
        retailerId: action.retailerId
      };
    case LOGIN_CLEAR_ERROR:
      return {
        ...state,
        error: null
      };
    case LOGIN_ERROR:
      {
        return {
          ...state,
          isLoading: false,
          error: action.error
        };
      }
    case LOGIN_START:
      return {
        ...state,
        isLoading: true,
        rememberRetailerId: Boolean(action.rememberRetailerId),
        retailerId: action.retailerId
      };
    case LOGIN_COMPLETE:
      return {
        ...state,
        isLoading: true,
        isComplete: true
      };
    case LOGIN_CLEAR_VALIDATION_ERRORS:
      return {
        ...state,
        validationErrors: null
      };
    case LOGIN_SET_REMEMBER_RETAILER_ID:
      return {
        ...state,
        rememberRetailerId: Boolean(action.rememberRetailerId)
      };
    case STORE_USER_METADATA_START:
      return {
        ...state,
        isLoading: true
      };
    case STORE_USER_METADATA_ERROR:
      return {
        ...state,
        isLoading: false,
        isMissingUserMetadata: true
      };
    case STORE_USER_METADATA_COMPLETE:
      return {
        ...state,
        isLoading: false,
        hasStoredUserMetadata: true
      };
    default:
      return state;
  }
}
export default loginReducer;