import { selectIsSpelombud } from '@common/selectors';
import { OVERVIEW, PLAYTOGETHER, LEARNING, PRODUCTOVERVIEW, TIPS_INSPIRATION } from './routes';
export const metadata = {
  title: 'Kunskap',
  routes: {
    [OVERVIEW]: {
      label: 'Översikt'
    },
    [LEARNING]: {
      label: 'Utbildning'
    },
    [PRODUCTOVERVIEW]: {
      label: 'Produkter'
    },
    [TIPS_INSPIRATION]: {
      label: 'Tips och inspiration'
    },
    [PLAYTOGETHER]: {
      label: 'Spela tillsammans',
      useIsAllowed: () => {
        return ReactRedux.useSelector(selectIsSpelombud);
      }
    }
  }
};