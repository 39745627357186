import { WelcomeBackground } from '../welcome-background';
import './styles.less';
const {
  Link
} = ReactRouterDOM;
const {
  Card,
  CardBody,
  CardHeader
} = svs.ui.ReactCard;
const {
  ReactIcon: Icon,
  ReactBank: BankId
} = svs.ui;
const {
  Grid,
  GridCol,
  GridRow
} = svs.ui.ReactGrid;
const {
  SiteHeaderBrand
} = svs.retailUi;
const Welcome = () => <WelcomeBackground className="component-welcome f-content">
    <div className="welcome-inner padding-bottom-3 margin-bottom-4">
      <div>
        <SiteHeaderBrand isDarkText size="large" />
      </div>
      <div className="f-center margin-bottom-4">
        <p>Det här är din Ombudsportal.  Här hittar du allt som kan hjälpa dig i din vardag. Logga in och ta del av allt.</p>
      </div>

      <Grid>
        <GridRow>
          <GridCol>
            <Link to="/logga-in/bank-id">
              <Card className="login-link">
                <CardHeader className="bg-red">
                  <div className="icon-circle">
                    <BankId name="bankid" size="30" />
                  </div>
                </CardHeader>
                <CardBody className="u-flex u-flex-justify-content-between">
                  Bank-id

                  <Icon icon="arrow-right" />
                </CardBody>
              </Card>
            </Link>
          </GridCol>

          <GridCol>
            <Link to="/logga-in/anvandarnamn">
              <Card className="login-link login-username">
                <CardHeader>
                  <div className="icon-circle">
                    <Icon icon="user" size="500" />
                  </div>
                </CardHeader>
                <CardBody className="u-flex u-flex-justify-content-between">
                  Användarnamn

                  <Icon icon="arrow-right" />
                </CardBody>
              </Card>
            </Link>
          </GridCol>
        </GridRow>
      </Grid>
    </div>
  </WelcomeBackground>;
export default Welcome;