import { LOGIN_START } from '../login/login.action-types';
import { authenticateComplete, loginError } from '../login/login.actions';
const {
  Authenticate
} = svs.retail;
const {
  Events
} = Authenticate;
const authenticateConnector = store => {
  const onAuthenticateError = options => {
    store.dispatch(loginError(options));
  };
  const onAuthenticateSuccess = ({
    mustChangePassword
  }) => {
    store.dispatch(authenticateComplete({
      mustChangePassword
    }));
  };
  const events = {
    [Events.AUTHENTICATE_ERROR]: onAuthenticateError,
    [Events.AUTHENTICATE]: onAuthenticateSuccess
  };
  Authenticate.on(events, this);
  return next => action => {
    if (!store.getState()?.login?.isLoading && action.type === LOGIN_START) {
      Authenticate.login({
        retailerId: action.retailerId,
        username: action.username,
        password: action.password
      });
    }
    return next(action);
  };
};
export { authenticateConnector };