import * as brandColors from './';
const logger = svs.core.log.getLogger('common/brand-colors');
export const getBrandColor = branding => {
  const key = (branding || '').toUpperCase().replace(/-/g, '_').replace(/Å|Ä|Ö/g, v => {
    switch (v) {
      case 'Å':
      case 'Ä':
        return 'A';
      case 'Ö':
        return 'O';
      default:
        return v;
    }
  });
  if (brandColors[key]) {
    return brandColors[key];
  }
  logger.warn(`Couldn't find color for branding '${branding}'.`);
  return brandColors.DEFAULT;
};