import { getRetailerIdIssueProposal, useQuery } from '@common/utils';
import { BankIdLogin } from '../components/bank-id-login';
import { bankIdApi } from '../store/bank-id-api';
import { authenticateComplete, changeRetailerId, setRememberRetailerId } from '../store/login/login.actions';
const {
  useCallback,
  useEffect,
  useState
} = React;
const {
  useDispatch,
  useSelector
} = ReactRedux;
const {
  useHistory
} = ReactRouterDOM;
const logger = svs.core.log.getLogger('welcome:BankIdLoginContainer');
const getErrorMessage = ({
  errorCode,
  isThisDevice,
  retailerId
}) => {
  switch (errorCode) {
    case 16:
      {
        const issueProposal = getRetailerIdIssueProposal(retailerId);
        return `Det gick inte att hitta någon användare med ditt personnummer för detta ombud. ${issueProposal}`;
      }
    case 29:
      return 'Ditt personnummer är kopplat till flera användare för detta ombud, be din ombudsadministratör att ta bort dubletterna.';
    case 41:
      logger.error(`Bank-id polling failed with errorCode '${errorCode}'`);
      return 'Ett okänt fel inträffade.';
    case 42:
      logger.error(`Bank-id polling failed with errorCode '${errorCode}' due to unknown autoStartToken.`);
      return 'Ett okänt fel inträffade, försök igen.';
    case 45:
      return 'Det BankID du försöker använda är för gammalt eller spärrat. Använd ett annat BankID eller hämta ett nytt hos din internetbank.';
    case 47:
      logger.error(`Bank-id polling failed with errorCode '${errorCode}'`);
      return 'Internt tekniskt fel. Försök igen.';
    case 49:
      return 'BankID-appen svarar inte. Kontrollera att den är startad och att du har internetanslutning. Om du inte har något giltigt BankID kan du hämta ett hos din bank. Försök sedan igen.';
    case 50:
      return 'En identifiering eller underskrift för det här personnumret är redan påbörjad. Försök igen.';
    case 51:
      return 'Åtgärden avbruten.';
    case 52:
      return 'Åtgärden avbruten. Försök igen.';
    case 56:
      if (isThisDevice) {
        return 'BankID-appen verkar inte finnas i din dator eller telefon. Installera den och hämta ett BankID hos din internetbank. ' + 'Installera appen från din appbutik eller https://install.bankid.com.';
      }
      return 'Misslyckades att läsa av QR koden. Starta BankID-appen och läs av QR koden. Kontrollera att BankID-appen är uppdaterad. ' + 'Om du inte har BankID-appen måste du installera den och hämta ett BankID hos din internetbank. Installera appen från din appbutik eller https://install.bankid.com.';
    default:
      logger.warn('Missing error message for errorCode.', {
        errorCode,
        isThisDevice
      });
      return 'Ett okänt fel inträffade.';
  }
};
export const BankIdLoginContainer = () => {
  const dispatch = useDispatch();
  const [isLoginThisDevice, setIsLoginThisDevice] = useState(null);
  const [isCanceled, setIsCanceled] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const {
    isComplete,
    retailerId,
    rememberRetailerId: storeRememberRetailerId
  } = useSelector(({
    login
  }) => login);
  const [initiateLogin, {
    data: ast,
    error: initiateLoginError,
    isLoading
  }] = bankIdApi.useInitiateLoginMutation();
  const [getAuthenticationStatus, {
    data: {
      qrData: statusQrData,
      session
    } = {},
    error: pollError
  }] = bankIdApi.useLazyGetAuthenticateStatusQuery();
  const query = useQuery();
  const history = useHistory();
  const queryAutoStartToken = query.get('autoStartToken');
  const queryIsLoginThisDevice = query.get('isLoginThisDevice') && query.get('isLoginThisDevice') === 'true';
  const statusPoller = React.useRef();
  useEffect(() => {
    if (initiateLoginError) {
      const ssnMessage = getRetailerIdIssueProposal(retailerId) || 'Har du angivit rätt ombudsnummer?';
      const errMsg = navigator.onLine ? ssnMessage : 'Inloggningen kunde inte slutföras på grund av obefintlig internetanslutning.';
      logger.info(`Initiate login failed with errorCode ${initiateLoginError.data?.error?.code}: ${errMsg}`);
      setErrorMessage(errMsg);
    }
  }, [initiateLoginError]);
  useEffect(() => {
    if (queryAutoStartToken) {
      logger.info('Got autoStartToken from query param, will start polling to get auth status.', {
        queryAutoStartToken
      });
      statusPoller.current = setInterval(() => getAuthenticationStatus(queryAutoStartToken), 1000);
    } else {
      clearInterval(statusPoller.current);
    }
    return () => {
      if (statusPoller.current) {
        clearInterval(statusPoller.current);
      }
    };
  }, [getAuthenticationStatus, statusPoller, queryAutoStartToken]);
  useEffect(() => {
    if (ast) {
      logger.info('Got autoStartToken from API, will update query params to trigger polling', {
        ast
      });
      const params = new URLSearchParams(query.toString());
      params.set('autoStartToken', ast.autoStartToken);
      params.set('isLoginThisDevice', isLoginThisDevice);
      history.push({
        search: params.toString()
      });
    }
  }, [ast, history, query, isLoginThisDevice]);
  useEffect(() => {
    if (isCanceled) {
      logger.info('Canceled login.');
      const params = new URLSearchParams(query.toString());
      params.delete('autoStartToken');
      params.delete('isLoginThisDevice');
      history.push({
        search: params.toString()
      });
      setIsCanceled(false);
    }
  }, [history, isCanceled, query]);
  useEffect(() => {
    if (session) {
      dispatch(authenticateComplete({
        isBankIdLogin: true,
        isLoginThisDevice
      }));
    }
  }, [dispatch, session]);
  const handleBankIdAuthenticateStart = ({
    isThisDevice,
    rememberRetailerId
  }) => {
    logger.info(`Will initiate login for retailer ${retailerId}, isThisDevice: ${isThisDevice}`);
    setIsLoginThisDevice(isThisDevice);
    initiateLogin(retailerId);
    dispatch(setRememberRetailerId(rememberRetailerId));
  };
  const handleChangeRetailerId = retailerId => {
    dispatch(changeRetailerId(retailerId));
  };
  const handleCancelLogin = useCallback(() => {
    dispatch(bankIdApi.util.resetApiState());
    setIsLoginThisDevice(null);
    setIsCanceled(true);
  }, [dispatch]);
  const handleClearErrorMessage = () => {
    setErrorMessage(null);
  };
  useEffect(() => {
    if (pollError) {
      const errorCode = pollError.data?.error?.code;
      let errMsg;
      if (pollError?.status === 'FETCH_ERROR') {
        errMsg = navigator.onLine ? 'Ett nätverksfel inträffade.' : 'Inloggningen kunde inte slutföras på grund av obefintlig internetanslutning.';
        logger.info(`Bank-id polling failed due to FETCH_ERROR': ${errMsg}`, {
          pollError
        });
      } else {
        errMsg = getErrorMessage({
          errorCode,
          isThisDevice: isLoginThisDevice,
          retailerId
        });
        logger.info(`Bank-id polling failed with errorCode '${errorCode}': ${errMsg}`, {
          pollError
        });
      }
      setErrorMessage(errMsg);
      handleCancelLogin();
    }
  }, [pollError, handleCancelLogin, isLoginThisDevice]);
  return <BankIdLogin autoStartToken={queryAutoStartToken} errorMessage={errorMessage} isLoading={isLoading} isLoginFinished={isComplete} isLoginThisDevice={queryIsLoginThisDevice} isRememberRetailerIdChecked={storeRememberRetailerId} onBankIdAuthenticateStart={handleBankIdAuthenticateStart} onCancelLogin={handleCancelLogin} onChangeRetailerId={handleChangeRetailerId} onCloseErrorDialog={handleClearErrorMessage} qrData={statusQrData ?? ast?.qrData} retailerId={retailerId} />;
};