import { PageHeader } from '@common/components/page-header';
import { PageIngress } from '@common/components/page-ingress';
const {
  createContext,
  useContext,
  useEffect,
  useState
} = React;
const {
  BrowserRouter
} = ReactRouterDOM;
const {
  useHistory
} = ReactRouterDOM;
const {
  ReactButton: Button,
  ReactIcon: Icon
} = svs.ui;
const {
  getLogger
} = svs.core.log;
const logger = getLogger('common:router');
const RouterListenerContext = createContext(null);
const Listener = ({
  children
}) => {
  const history = useHistory();
  const [hasNavigated, setHasNavigated] = useState(false);
  useEffect(() => {
    const unlisten = history.listen(() => {
      setHasNavigated(true);
      unlisten();
    });
  }, [history]);
  return <RouterListenerContext.Provider value={hasNavigated}>
      {children}
    </RouterListenerContext.Provider>;
};
export const NotFoundComponent = () => {
  const hasNavigated = useContext(RouterListenerContext);
  if (hasNavigated) {
    logger.info(`No React route found for ${window.location.pathname}, will do a full page reload.`);
    window.location.reload();
    return <PageHeader breadcrumb="" breadcrumbLink="/" isLoading />;
  }
  logger.warn(`404: No route found for ${window.location.pathname}.`);
  return <React.Fragment>
      <PageHeader title="Här var ingen hemma" />

      <div className="ompo-main-content f-center padding-top-4">
        <Icon color="fc-red" icon="icecream" size="1000" />
      </div>

      <PageIngress>
        <p className="f-center">
          Du har kanske skrivit fel webbadress eller klickat på en gammal länk?
          <br />
          Kontrollera adressen, eller testa alternativen nedan.
          <br />
          Lycka till!
        </p>
      </PageIngress>

      <div className="ompo-main-content u-flex u-flex-justify-content-center padding-bottom-4">
        <Button component="a" href="/" inverted>
          Till startsidan
        </Button>

        <Button className="btn-double-lines margin-left-1" component="a" href="tel:0770111111" inverted>
          Ring kundservice
          <br />
          0770 - 11 11 11
        </Button>
      </div>
    </React.Fragment>;
};
export const Router = ({
  basename,
  children
}) => <BrowserRouter basename={basename}>
    <Listener>
      {children}
    </Listener>
  </BrowserRouter>;
Router.defaultProps = {
  basename: svs.core.data.baseUrl
};
Router.propTypes = {
  basename: PropTypes.string,
  children: PropTypes.node
};