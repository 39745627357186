import { loginClearError, storeUserMetadata } from '../store/login/login.actions';
import { loginComplete } from '../store/login/login.actions';
import { Login } from '../components/login';
import { retailerApi } from '@common/retailer-api';
const {
  useEffect
} = React;
const {
  useDispatch,
  useSelector
} = ReactRedux;
export const LoginContainer = () => {
  const dispatch = useDispatch();
  const [getRetailerInfo, {
    data: retailerInfo,
    error: retailerInfoError
  }] = retailerApi.useLazyGetUserProfileQuery();
  const {
    hasStoredUserMetadata,
    isAuthenticated,
    isComplete,
    isMissingUserMetadata,
    error
  } = useSelector(({
    login
  }) => login);
  const hasToChangePassword = useSelector(({
    login
  }) => login.isComplete && login.mustChangePassword);
  useEffect(() => {
    if (isAuthenticated && !isComplete) {
      getRetailerInfo();
    }
  }, [isAuthenticated, isComplete, getRetailerInfo]);
  useEffect(() => {
    if (retailerInfo) {
      dispatch(storeUserMetadata(retailerInfo));
    }
  }, [dispatch, retailerInfo, retailerInfoError]);
  useEffect(() => {
    if (hasStoredUserMetadata) {
      dispatch(loginComplete());
    }
  }, [dispatch, hasStoredUserMetadata]);
  const handleCloseMetadataErrorDialog = () => dispatch(loginComplete());
  const handleCloseErrorDialog = () => dispatch(loginClearError());
  return <Login error={error} hasMissingUserMetadata={Boolean(retailerInfoError) || isMissingUserMetadata} hasToChangePassword={hasToChangePassword} isAuthenticated={isAuthenticated} isComplete={isComplete} onCloseErrorDialog={handleCloseErrorDialog} onCloseMetadataErrorDialog={handleCloseMetadataErrorDialog} />;
};