export const TENANT_LABELS = {
  soc: 'Sport & Casino',
  tur: 'Tur'
};
export const TENANT_IDS = {
  MB: 'mb',
  SOC: 'soc',
  TUR: 'tur'
};
export const SELL_TYPE_IDS = {
  SPEL_LOTT: 1,
  SPEL: 2,
  LOTT: 3
};
export const BRANDINGS = {
  VINNARE: 'vinnare',
  SOC: 'soc',
  TUR: 'tur'
};
export const DISCONTINUED = 'F';
export const SMALL_SCREEN = 'small';
export const ORDER_STATUS_PROCESSING = 'Bearbetas';
export const SVS_ORDER_TYPE = 'svs';
export const CAMPAIGN_ORDER_TYPE = 'campaign';
export const ORDER_ORDER_TYPE = 'order';